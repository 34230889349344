<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <!-- Filtro por período -->
            <ul class="nav line-tabs borderless flex-center mb-5">
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'weekly'}" v-on:click="setTempPeriod('weekly')" ref="view_type_weekly" data-toggle="pill" data-period="weekly">{{ $t('period.weekly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'monthly'}" v-on:click="setTempPeriod('monthly')" ref="view_type_monthly"  data-toggle="pill" data-period="monthly">{{ $t('period.monthly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'yearly'}" v-on:click="setTempPeriod('yearly')"  ref="view_type_yearly" data-toggle="pill" data-period="yearly">{{ $t('period.yearly') }}</a>
              </li>
            </ul>

            <!-- Filtros -->
            <h5 class="mb-0">Filtrar por:</h5>
            <form id="report-statement-weekly-list-filter" v-on:submit.prevent="applyFilter()">
              <!-- Cliente -->
              <div>
                <div>
                  <multiple-select :options="customerList" :id-field="'id_customer'" :placeholder="this.$i18n.t('form.customer.label')" :fetchOptions="customerFetchOptions" :value="filters.filCliente" />
                </div>
              </div>

              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-6">
                        <div class="md-form mb-0">
                          <select class="md-form-control" :value="filters.filYear" id="year" name="year" ref="year" >
                            <option v-for="(item, index) in reportData.years" v-bind:key="index" :value="item" :selected="filters.filYear === item" >{{ item }}</option>
                          </select>
                          <label :class="{'active': true}">{{ $t('form.report.year') }}</label>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod == 'weekly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filWeek" id="week" name="week" ref="week" >
                          <option v-for="(week, index) in reportData.weeks" v-bind:key="index" :value="index" :selected="filters.filWeek === parseInt(index)" >{{ week }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.week') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod == 'monthly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filMonth" id="month" name="month" ref="month" >
                          <option v-for="(month, index) in reportData.months" v-bind:key="index" :value="index" :selected="filters.filMonth === parseInt(index)" >{{ month}}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.month') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Operation / Modal -->
              <h6 class="mb-3 mt-4">{{ $t('operation type and modal') }}</h6>
              <div class="row">
                <!-- Filter Operation  -->
                <div class="col-md-6">
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Importação</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.impo}">
                        <input id="operation_impo" value="2" :disabled="disable.impo" v-on:click="validaFiltro" type="checkbox" ref="operation_impo" :checked="filters.filTipoOperacaoModalImportacao"><span></span></label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Exportação</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.expo}">
                        <input id="operation_expo" value="1" :disabled="disable.expo" v-on:click="validaFiltro" type="checkbox" ref="operation_expo" :checked="filters.filTipoOperacaoModalExportacao"><span></span>
                      </label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Nacional</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.nac}">
                        <input id="operation_nac" value="3" :disabled="disable.nac" v-on:click="validaFiltro" type="checkbox"  ref="operation_nac" :checked="filters.filTipoOperacaoModalNacional"><span></span>
                      </label>
                    </div>
                  </div>
                </div>
                <!-- Filter Modal  -->
                <div class="col-md-6">
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Marítimo</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.mar}">
                        <input id="modal_mar" value="2" :disabled="disable.mar" v-on:click="validaFiltro" type="checkbox" ref="modal_mar" :checked="filters.filTipoOperacaoModalMaritimo"><span></span></label></div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Aéreo</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.air}">
                        <input id="modal_air" value="1" :disabled="disable.air" v-on:click="validaFiltro" type="checkbox" ref="modal_air" :checked="filters.filTipoOperacaoModalAereo"><span></span>
                      </label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Terrestre</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.land}">
                        <input id="modal_land" value="3" :disabled="disable.land" v-on:click="validaFiltro" type="checkbox"  ref="modal_land" :checked="filters.filTipoOperacaoModalTerrestre"><span></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Show api requests -->
              <h6 class="mb-3 mt-4">{{ $t('Tipo Requisição') }}</h6>
              <div class="row">
                <!-- API -->
                <div class="col-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">API</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.api}">
                        <input id="tipo_requisicao_api" value="2" :disabled="disable.api" v-on:click="validaFiltroTipoRequisicao" type="checkbox" ref="tipo_requisicao_api" :checked="filters.filTipoRequisicaoAPI"><span></span></label>
                    </div>
                  </div>
                </div>
                <!-- Portal -->
                <div class="col-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Portal</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.portal}">
                        <input id="tipo_requisicao_portal" value="1" :disabled="disable.portal" v-on:click="validaFiltroTipoRequisicao" type="checkbox" ref="tipo_requisicao_portal" :checked="filters.filTipoRequisicaoPortal"><span></span></label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <div class="col-12">
        <button-back>
          COTAÇÕES ONLINE
        </button-back>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="getActiveFilterList" />
      </div>

      <!--<div class="col-12">
        <pre>{{ chartQtdCotacoes }}</pre>
        <pre> {{ labelChartQtdCotacoes }}</pre>
      </div>-->

      <div class="col-6 col-sm-6 col-md-6 p-0">
        <div class="col-12 col-sm-12 col-md-12">
          <summary-box
            title="Opções disponibilizadas"
            :value="reportData.summary.opcoesDisponiveis.value"
            :percent="reportData.summary.opcoesDisponiveis.percent"
            :description="getDescription(reportData.summary.opcoesDisponiveis.value, reportData.summary.opcoesDisponiveis.previous, 'opções disponibilizadas ')"
          />
        </div>

        <div class="col-12 col-sm-12 col-md-12">
          <summary-box
            title="Pré-reserva"
            :value="reportData.summary.pre_reserva.value"
            :percent="reportData.summary.pre_reserva.percent"
            :description="getDescription(reportData.summary.pre_reserva.value, reportData.summary.pre_reserva.previous, 'pré-reservas')"
          />
        </div>

        <div class="col-12 col-sm-12 col-md-12">
          <summary-box
            title="Solicitações de acesso"
            :value="reportData.summary.solicitacoesAcesso.value"
            :percent="reportData.summary.solicitacoesAcesso.percent"
            :description="getDescription(reportData.summary.solicitacoesAcesso.value, reportData.summary.solicitacoesAcesso.previous, 'solicitações de acesso')"
          />
        </div>

        <!-- <div class="col-12 col-sm-12 col-md-12">
          <summary-box
            title="Buscas"
            :value="reportData.summary.buscas.value"
            :percent="reportData.summary.buscas.percent"
            :description="getDescription(reportData.summary.buscas.value, reportData.summary.buscas.previous, 'buscas')"
          />
        </div> -->

      </div>
      <div class="col-6 col-sm-6 col-md-6 p-0">
        <div class="col-12 col-sm-12 col-md-12">
          <DetailedSearchesBox
            comparLeft="Total de Buscas"
            comparRight="Buscas sem opções"
            :valueLeft="reportData.summary.buscas.value"
            :valueRight="reportData.summary.buscasSemOpcoes.value"
            :percentLeft="reportData.summary.buscas.percent"
            :percentRight="reportData.summary.buscasSemOpcoes.percent"
            :descriptionLeft="getDescription(reportData.summary.buscas.value, reportData.summary.buscas.previous, 'buscas')"
            :descriptionRight="getDescription(reportData.summary.buscasSemOpcoes.value, reportData.summary.buscasSemOpcoes.previous, 'buscas sem opções')"
            comparisonText="das buscas não tiveram opções"
            titleBox1="Importação"
            :valueBox1="reportData.summary.produtosBuscados.impo"
            :percentBox1="reportData.summary.produtosBuscados.percentImpo"
            :descriptionBox1="getDescription(reportData.summary.produtosBuscados.impo, reportData.summary.produtosBuscados.previousImpo, 'buscas')"
            titleBox2="Exportação"
            :valueBox2="reportData.summary.produtosBuscados.expo"
            :percentBox2="reportData.summary.produtosBuscados.percentExpo"
            :descriptionBox2="getDescription(reportData.summary.produtosBuscados.expo, reportData.summary.produtosBuscados.previousExpo, 'buscas')"
            titleBox3="Nacional"
            :valueBox3="reportData.summary.produtosBuscados.nacional"
            :percentBox3="reportData.summary.produtosBuscados.percentNacional"
            :descriptionBox3="getDescription(reportData.summary.produtosBuscados.nacional, reportData.summary.produtosBuscados.previousNacional, 'buscas')"
            titleBox4="Marítimo"
            :valueBox4="reportData.summary.produtosBuscados.maritimo"
            :percentBox4="reportData.summary.produtosBuscados.percentMaritimo"
            :descriptionBox4="getDescription(reportData.summary.produtosBuscados.maritimo, reportData.summary.produtosBuscados.previousMaritimo, 'buscas')"
            titleBox5="Aéreo"
            :valueBox5="reportData.summary.produtosBuscados.aereo"
            :percentBox5="reportData.summary.produtosBuscados.percentAereo"
            :descriptionBox5="getDescription(reportData.summary.produtosBuscados.aereo, reportData.summary.produtosBuscados.previousAereo, 'buscas')"
            titleBox6="Rodoviário"
            :valueBox6="reportData.summary.produtosBuscados.rodoviario"
            :percentBox6="reportData.summary.produtosBuscados.percentRodoviario"
            :descriptionBox6="getDescription(reportData.summary.produtosBuscados.rodoviario, reportData.summary.produtosBuscados.previousRodoviario, 'buscas')"
          />
        </div>
      </div>
    </div>

    <!-- Gráficos -->
    <div class="row justify-content-md-center">
      <!-- Média Cotações -->
      <div class="col-12">
        <div class="card card-fullheight">
          <!-- Header-->
          <div class="card-header border-0">
            <h5 class="box-title">{{ $t('Quantidade ' + periodDescription() + ' de cotações') }}</h5>
          </div>
          <!-- Content -->
          <div class="card-body">
            <area-chart-new :data="chartQtdCotacoes" :labels="labelChartQtdCotacoes" :is-loading="loadingQtdCotacoes" />
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-md-center">
      <!-- TOP Origem X Destino -->
      <div class="col-12 col-sm-6 col-md-6">
        <div class="card card-fullheight">
          <!-- Header-->
          <div class="card-header border-0">
            <h5 class="box-title">{{ $t('Top 5 Origem X Destino') }}</h5>
            <div class="card-actions">
              <a class="dropdown-toggle no-arrow" data-toggle="dropdown"><i class="ti-more-alt"></i></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a  v-on:click="showAllOriginDestination" class="dropdown-item d-flex align-items-center">{{ $t('Todos') }}</a>
              </div>
            </div>
          </div>

          <!-- Content -->
          <div class="card-body">
          <table class="table table-hover align-middle table-clickable fixed">
          <thead>
            <tr>
              <th>#</th>
              <th>Origem</th>
              <th>Destino</th>
              <th>Com Opções</th>
              <th>Sem Opções</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(origemDestino, index) in reportData.top_origem_destino" v-bind:key="index">
              <td>{{ index +1 }}</td>
              <td>{{ origemDestino.origem }}</td>
              <td>{{ origemDestino.destino }}</td>
              <td>{{ origemDestino.opcoesdisponiveis }}</td>
              <td>{{ origemDestino.buscassemopcoes }}</td>
              <td>{{ origemDestino.buscas }}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>

      <!-- TOP Clientes -->
      <div class="col-12 col-sm-6 col-md-6">
        <div class="card card-fullheight">
            <!-- Header-->
            <div class="card-header border-0">
              <h5 class="box-title">{{ $t('Top 5 Clientes') }}</h5>
              <div class="card-actions">
                <a class="dropdown-toggle no-arrow" data-toggle="dropdown"><i class="ti-more-alt"></i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a  v-on:click="showAllCustomer()" class="dropdown-item d-flex align-items-center">{{ $t('Todos') }}</a>
                </div>
              </div>
            </div>
          <!-- Content -->
          <div class="card-body">
            <table class="table table-hover align-middle table-clickable fixed">
              <thead>
              <tr>
                <th>#</th>
                <th>Cliente</th>
                <th>Com Opções</th>
                <th>Sem Opções</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(cliente, index) in reportData.top_cliente" v-bind:key="index">
                <td>{{ index + 1}}</td>
                <td>{{ cliente.cliente }}</td>
                <td>{{ cliente.opcoesdisponiveis }}</td>
                <td>{{ cliente.buscassemopcoes }}</td>
                <td>{{ cliente.buscas }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Buscas -->
      <div class="col-12 col-sm-12 col-md-12">
        <div class="card card-fullheight">
          <!-- Header-->
          <div class="card-header border-0">
            <h5 v-if="filterSearchStatus === 2" class="box-title">{{ $t('Sem opções de frete') }}</h5>
            <h5 v-else-if="filterSearchStatus === 1" class="box-title">{{ $t('Com opções de frete') }}</h5>
            <h5 v-else class="box-title">{{ $t('Todas as buscas') }}</h5>

            <div class="card-actions">
              <a class="dropdown-toggle no-arrow" data-toggle="dropdown"><i class="ti-filter"></i></a>
              <div class="dropdown-menu dropdown-menu-right">
                <a  v-on:click="onSearchStatusFilter(null)" class="dropdown-item d-flex align-items-center">Todas as buscas</a>
                <a v-on:click="onSearchStatusFilter(2)" class="dropdown-item d-flex align-items-center">Sem opções de frete</a>
                <a v-on:click="onSearchStatusFilter(1)" class="dropdown-item d-flex align-items-center">Com opções de frete</a>
              </div>
            </div>
          </div>
          <!-- Content -->
          <div class="card-body">
            <custom-vue-table
              ref="searchTable"
              http-method="get"
              :api-url="loadSearchEndpoint"
              :fields="fieldsTable"
              :sort-order="sortOrder"
              :append-params="searchActiveFilters"
              track-by="id"
            >
              <template slot="contato" slot-scope="props">
                {{ (props.rowData.usuario_nome) ? props.rowData.usuario_nome + ' (' + props.rowData.usuario_email + ')': props.rowData.usuario_email }}
              </template>
            </custom-vue-table>
          </div>
        </div>
      </div>

      <!-- Solicitações de acesso -->
      <div class="col-12 col-sm-12 col-md-12">
        <div class="card card-fullheight">
          <!-- Header-->
          <div class="card-header border-0">
            <h5 lass="box-title">{{ $t('Solicitações de acesso') }}</h5>
          </div>
          <!-- Content -->
          <div class="card-body">
            <table class="table table-hover align-middle table-clickable fixed">
              <thead>
              <tr>
                <th>Data solicitação</th>
                <th>Cliente</th>
                <th>Contato</th>
                <th>Situação</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(solicitacaoAcesso, index) in reportData.solicitacao_acesso" v-bind:key="index">
                <td>{{ $util.formatDateTime(solicitacaoAcesso.data_solicitacao) }}</td>
                <td>{{ solicitacaoAcesso.cliente }} {{ solicitacaoAcesso.cliente_cnpj}}</td>
                <td>{{ solicitacaoAcesso.usuario_nome }} {{ solicitacaoAcesso.usuario_email }}</td>
                <td>{{ solicitacaoAcesso.situacao }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Components
import SummaryBox from '@/components/Basic/SummaryBox'
import DetailedSearchesBox from '@/components/report/DetailedSearchesBox'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import CustomVueTable from '@/components/Table/CustomVueTable'
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'
import OriginDestinationModal from './OriginDestinationModal'
import CustomerModal from './CustomerModal'
import ButtonBack from '@/components/Navigate/ButtonBack'
import AreaChartNew from '@/components/ChartJs/AreaChartNew'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// DatePicker
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import ReportService from '@/services/ReportService'
import CustomerService from '@/services/CustomerService'

export default {
  name: 'ReportProposal',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.title') + ' ' + this.$i18n.t('report.proposal.title') + ' - %s'
    }
  },
  data () {
    return {
      loadingQtdCotacoes: true,
      // Filters
      customerList: [],
      chartQtdCotacoes: [],
      labelChartQtdCotacoes: [],
      chartQtdCotacoesAnterior: [],
      // Search
      loadSearchEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/indicator/proposal/search',
      searchStatus: null,
      sortOrder: [
        { field: 'data_consulta', direction: 'desc' }
      ],
      // END Search
      tempPeriod: 'weekly',
      seller: {},
      reportData: {
        data: [],
        summary: {
          produtosBuscados: {
            aereo: 0,
            expo: 0,
            impo: 0,
            maritimo: 0,
            nacional: 0,
            percentAereo: 0,
            percentExpo: 0,
            percentImpo: 0,
            percentMaritimo: 0,
            percentNacional: 0,
            percentRodoviario: 0,
            previousAereo: 0,
            previousExpo: 0,
            previousImpo: 0,
            previousMaritimo: 0,
            previousNacional: 0,
            previousRodoviario: 0,
            rodoviario: 0
          },
          pre_reserva: {
            value: 0,
            percent: 0,
            previous: 0
          },
          buscas: {
            value: 0,
            percent: 0,
            previous: 0
          },
          opcoesDisponiveis: {
            value: 0,
            percent: 0,
            previous: 0
          },
          buscasSemOpcoes: {
            value: 0,
            percent: 0,
            previous: 0
          },
          solicitacoesAcesso: {
            value: 0,
            percent: 0,
            previous: 0
          }
        }
      },
      isLoading: true,
      fullPage: true,
      activeFilterList: {
        tipo_requisicao: null,
        period: null,
        year: null,
        week: null,
        month: null,
        modal: true,
        operation: true
      },
      filter: {
        searchStatus: null
      },
      disable: {
        expo: false,
        impo: false,
        nac: false,
        mar: false,
        air: false,
        land: false,
        api: false,
        portal: false
      }
    }
  },
  components: {
    FilterSideBar,
    Loading,
    FilterContainer,
    SummaryBox,
    CustomVueTable,
    MultipleSelect,
    DetailedSearchesBox,
    ButtonBack,
    AreaChartNew
  },
  beforeMount () {
    /**
     * Returns the week number for this date.  dowOffset is the day of week the week
     * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
     * the week returned is the ISO 8601 week number.
     * @param int dowOffset
     * @return int
     */
    // eslint-disable-next-line
    Date.prototype.getWeek = function (dowOffset) {
      // default dowOffset to zero
      dowOffset = dowOffset !== undefined && typeof (dowOffset) === 'number' ? dowOffset : 0
      let newYear = new Date(this.getFullYear(), 0, 1)
      // the day of week the year begins on
      let day = newYear.getDay() - dowOffset
      day = (day >= 0 ? day : day + 7)
      let daynum = Math.floor((this.getTime() - newYear.getTime() -
        (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1
      let weeknum
      // if the year starts before the middle of a week
      if (day < 4) {
        weeknum = Math.floor((daynum + day - 1) / 7) + 1
        if (weeknum > 52) {
          let nYear = new Date(this.getFullYear() + 1, 0, 1)
          let nday = nYear.getDay() - dowOffset
          nday = nday >= 0 ? nday : nday + 7
          // if the next year starts before the middle of the week, it is week #1 of that year
          weeknum = nday < 4 ? 1 : 53
        }
      } else {
        weeknum = Math.floor((daynum + day - 1) / 7)
      }
      return weeknum
    }

    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    if (this.user) {
      this.getReportProposalData()
    }
  },
  methods: {
    periodDescription () {
      let description = 'mensal'
      if (this.filter.period === 'weekly') {
        description = 'semanal'
      } else if (this.filter.period === 'monthly') {
        description = 'mensal'
      } else if (this.filter.period === 'yearly') {
        description = 'anual'
      }

      return description
    },
    addChartBarData (chart, data) {
      this.legend = []
      chart.data.labels = []
      chart.data.datasets = []
      chart.update()
      chart.data.labels = data.labels
      if (data.hasData) {
        for (var propertyName in data.dataset) {
          let item = data.dataset[propertyName]

          if (!item.show) {
            continue
          }

          for (let valuePropertyName in item.data) {
            let value = item.data[valuePropertyName]

            // Filter Layers
            if (this.filter.processes.indexOf(1) <= -1 && valuePropertyName.indexOf('opened') !== -1) {
              continue
            } else if (this.filter.processes.indexOf(2) <= -1 && valuePropertyName.indexOf('boarded') !== -1) {
              continue
            }

            let dataSet = {
              label: item.title,
              attribute: value.label,
              total: value.total,
              backgroundColor: value.color,
              stack: 1,
              data: value.values
            }

            if (value.type) {
              dataSet.type = value.type
              dataSet.fill = false
              dataSet.borderWidth = 2
              dataSet.lineTension = 0.1
              dataSet.borderColor = value.color
            }
            chart.data.datasets.push(dataSet)
            this.legend.push(dataSet)
          }
        }
        chart.update()
      }
    },
    showAllCustomer () {
      this.$modal.show(CustomerModal, {
        filters: this.filter
      },
      {
        draggable: false,
        adaptive: true,
        scrollable: true,
        clickToClose: false,
        width: '90%',
        height: 'auto'
      })
    },
    showAllOriginDestination () {
      this.$modal.show(OriginDestinationModal, {
        filters: this.filter
      },
      {
        draggable: false,
        adaptive: true,
        scrollable: true,
        clickToClose: false,
        width: '90%',
        height: 'auto'
      })
    },
    onSearchStatusFilter (val) {
      this.filter.searchStatus = val
      this.$refs.searchTable.refresh()
    },
    setTempPeriod (item) {
      this.tempPeriod = item
    },
    getDescription (current, previous, label, prefix) {
      let lessThanInfo = 'na semana anterior.'
      let equalInfo = 'a semana anterior.'

      if (this.filter.period === 'yearly') {
        lessThanInfo = 'no ano anterior.'
        equalInfo = 'o ano anterior.'
      } else if (this.filter.period === 'monthly') {
        lessThanInfo = 'no mês anterior.'
        equalInfo = 'o mês anterior.'
      }

      let diferenca = current - previous
      prefix = prefix || ''
      if ((diferenca) > 0) {
        return prefix + this.showNumber(diferenca) + ' ' + label + ' a mais que ' + lessThanInfo
      } else if ((diferenca) === 0) {
        return prefix + ' ' + label + ' iguais ' + equalInfo
      } else {
        return prefix + this.showNumber(diferenca * -1.0) + ' ' + label + ' a menos que ' + lessThanInfo
      }
    },
    buildFilters () {
      this.filter.period = this.filters.filPeriodo
      this.tempPeriod = this.filters.filPeriodo

      /**
       *  Não primeira abertura seta o filtros com o padrão
       *   - Mês: Atual
       *   - Ano: Atual
       *   - Colaborador: Usuário autenticado
       *  */

      let today = new Date()
      if (!this.filters.filYear && !this.filters.filWeek && !this.filters.filMonth && (!this.filters.filEmployees || this.filters.filEmployees.length <= 0)) {
        this.filters.filYear = today.getFullYear()
        this.filters.filWeek = today.getWeek()
        this.filters.filMonth = today.getMonth() + 1

        this.$store.dispatch('setAppliedFilter', this.filters)
      }

      if ((!this.filters.filWeek)) {
        this.filters.filWeek = today.getWeek()
      }

      // TipoRequisicao Filter
      this.filter.tipo_requisicao = []
      if (this.filters.filTipoRequisicaoAPI) {
        this.filter.tipo_requisicao.push(this.filters.filTipoRequisicaoAPI)
      }
      if (this.filters.filTipoRequisicaoPortal) {
        this.filter.tipo_requisicao.push(this.filters.filTipoRequisicaoPortal)
      }

      // Operation Filter
      this.filter.operation = []
      if (this.filters.filTipoOperacaoModalExportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalExportacao)
      }
      if (this.filters.filTipoOperacaoModalImportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalImportacao)
      }
      if (this.filters.filTipoOperacaoModalNacional) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalNacional)
      }

      // Modal Filter
      this.filter.modal = []
      if (this.filters.filTipoOperacaoModalTerrestre) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalTerrestre)
      }
      if (this.filters.filTipoOperacaoModalMaritimo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalMaritimo)
      }
      if (this.filters.filTipoOperacaoModalAereo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalAereo)
      }

      if ((!this.filters.filMonth)) {
        this.filters.filMonth = today.getMonth() + 1
      }

      this.filter.employees = (this.filters.filEmployees) ? this.filters.filEmployees.map(x => (x.id)) : []
      this.filter.year = this.filters.filYear
      this.filter.week = this.filters.filWeek
      this.filter.month = this.filters.filMonth

      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
    },
    // Filter
    applyFilter () {
      // Period
      if (this.$refs.view_type_weekly.classList.contains('active')) {
        this.filters.filPeriodo = 'weekly'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }

      // TipoRequisicao Filter
      this.filters.filTipoRequisicaoAPI = this.$refs.tipo_requisicao_api.checked ? parseInt(this.$refs.tipo_requisicao_api.value) : null
      this.filters.filTipoRequisicaoPortal = this.$refs.tipo_requisicao_portal.checked ? parseInt(this.$refs.tipo_requisicao_portal.value) : null

      // Operation Filter
      this.filters.filTipoOperacaoModalExportacao = this.$refs.operation_expo.checked ? parseInt(this.$refs.operation_expo.value) : null
      this.filters.filTipoOperacaoModalImportacao = this.$refs.operation_impo.checked ? parseInt(this.$refs.operation_impo.value) : null
      this.filters.filTipoOperacaoModalNacional = this.$refs.operation_nac.checked ? parseInt(this.$refs.operation_nac.value) : null

      // Modal Filter
      this.filters.filTipoOperacaoModalTerrestre = this.$refs.modal_land.checked ? parseInt(this.$refs.modal_land.value) : null
      this.filters.filTipoOperacaoModalMaritimo = this.$refs.modal_mar.checked ? parseInt(this.$refs.modal_mar.value) : null
      this.filters.filTipoOperacaoModalAereo = this.$refs.modal_air.checked ? parseInt(this.$refs.modal_air.value) : null

      this.activeFilterList = {
        customer: [],
        period: null,
        year: null,
        tipo_requisicao: null,
        modal: true,
        operation: true
      }

      if (this.filters.filPeriodo === 'weekly') {
        this.activeFilterList.week = null
      } else if (this.filters.filPeriodo === 'monthly') {
        this.activeFilterList.month = null
      }

      // Filter Processes

      // this.filter.employees = (this.filters.filEmployees) ? this.filters.filCliente.map(x => (x.id)) : []
      // this.filters.filEmployees = this.$refs.employee ? parseInt(this.$refs.employee.value) : []
      this.filters.filYear = this.$refs.year ? parseInt(this.$refs.year.value) : null
      this.filters.filWeek = this.$refs.week ? parseInt(this.$refs.week.value) : null
      this.filters.filMonth = this.$refs.month ? parseInt(this.$refs.month.value) : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      // this.$refs.vuetable.refresh()

      /* Todo: Reload Charts */
      this.getReportProposalData()
      this.$refs.searchTable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()
      // hasFilter: false
      let today = new Date()

      this.filters.filYear = today.getFullYear()
      this.filters.filWeek = []
      this.filters.filMonth = today.getMonth() + 1
      this.filters.filPeriodo = 'monthly'
      this.filters.filEmployees = []
      this.filters.filCliente = []

      this.filters.filTipoRequisicaoAPI = 2
      this.filters.filTipoRequisicaoPortal = 1

      this.filters.filTipoOperacaoModalAereo = 1
      this.filters.filTipoOperacaoModalMaritimo = 2
      this.filters.filTipoOperacaoModalTerrestre = 3

      this.filters.filTipoOperacaoModalExportacao = 1
      this.filters.filTipoOperacaoModalImportacao = 2
      this.filters.filTipoOperacaoModalNacional = 3

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()
      this.getReportProposalData()

      this.$refs.searchTable.refresh()
    },
    validaFiltro () {
      let contOp = 0
      let contMo = 0

      contOp = this.$refs.operation_expo.checked ? (contOp + 1) : contOp
      contOp = this.$refs.operation_impo.checked ? (contOp + 1) : contOp
      contOp = this.$refs.operation_nac.checked ? (contOp + 1) : contOp
      contMo = this.$refs.modal_mar.checked ? (contMo + 1) : contMo
      contMo = this.$refs.modal_air.checked ? (contMo + 1) : contMo
      contMo = this.$refs.modal_land.checked ? (contMo + 1) : contMo

      this.disable.expo = false
      this.disable.impo = false
      this.disable.nac = false
      this.disable.mar = false
      this.disable.air = false
      this.disable.land = false

      if (contOp === 1) {
        switch (true) {
          case this.$refs.operation_expo.checked:
            this.disable.expo = true
            break
          case this.$refs.operation_impo.checked:
            this.disable.impo = true
            break
          case this.$refs.operation_nac.checked:
            this.disable.nac = true
            break
        }
      }

      if (contMo === 1) {
        switch (true) {
          case this.$refs.modal_mar.checked:
            this.disable.mar = true
            break
          case this.$refs.modal_air.checked:
            this.disable.air = true
            break
          case this.$refs.modal_land.checked:
            this.disable.land = true
            break
        }
      }
    },
    validaFiltroTipoRequisicao () {
      let contOp = 0

      contOp = this.$refs.tipo_requisicao_portal.checked ? (contOp + 1) : contOp
      contOp = this.$refs.tipo_requisicao_api.checked ? (contOp + 1) : contOp
      this.disable.api = false
      this.disable.portal = false

      if (contOp === 1) {
        switch (true) {
          case this.$refs.tipo_requisicao_portal.checked:
            this.disable.portal = true
            break
          case this.$refs.tipo_requisicao_api.checked:
            this.disable.api = true
            break
        }
      }
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    showNumber (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'
      if (number !== parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // End Filter
    getReportProposalData () {
      let me = this
      me.isLoading = true

      if (this.$store.getters.userInfo) {
        ReportService.getIndicatorProposal(this.filter.year, this.filter.week, this.filter).then(res => {
          me.seller = res.data.seller
          me.reportData = res.data
          me.loadingQtdCotacoes = true
          ReportService.getIndicatorQtdCotacoes(this.filter).then(resQtdCotacoes => {
            me.loadingQtdCotacoes = false
            me.labelChartQtdCotacoes = resQtdCotacoes.data.labels
            // me.chartQtdCotacoes = resQtdCotacoes.data
            me.chartQtdCotacoes = resQtdCotacoes.data.values
          })

          me.isLoading = false
        })
      }
    },
    generateImage (value) {
      let baseUrl = process.env.VUE_APP_BaseURL + '/'

      let imageUrl = baseUrl + 'static/images/customers/no_photo.jpeg'
      if (value) {
        let fileId = value.split('id=')
        imageUrl = 'http://drive.google.com/uc?export=view&id=' + fileId[1]
      }

      return imageUrl
    },
    asset (value) {
      let baseUrl = process.env.VUE_APP_BaseURL + '/'

      let assetUrl = baseUrl + value

      return assetUrl
    }
  },
  computed: {
    filterSearchStatus () {
      return (this.filter.hasOwnProperty('searchStatus')) ? this.filter.searchStatus : null
    },
    searchActiveFilters () {
      return this.filter
    },
    fieldsTable () {
      return [
        {
          name: 'data_consulta',
          title: 'Data consulta',
          sortField: 'data_consulta',
          formatter: (value) => this.$util.formatDateTime(value)
        },
        {
          name: 'cliente',
          title: 'Cliente',
          sortField: 'cliente',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'contato',
          title: 'Contato'
        },
        // {
        //   name: '',
        //   title: 'Contato',
        //   sortField: '',
        //   formatter: (value) => this.$util.formatEmpty(value)
        // },
        {
          name: 'operacao',
          title: 'Operação',
          sortField: 'operacao',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'modalidade_processo',
          title: 'Modalidade',
          sortField: 'modalidade_processo',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'origem',
          title: 'Origem',
          sortField: 'origem',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'destino',
          title: 'Destino',
          sortField: 'destino',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'mercadoria',
          title: 'Mercadoria',
          sortField: 'mercadoria',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'equipamento',
          title: 'Equipamento(s)',
          sortField: 'equipamento',
          formatter: (value) => this.$util.formatUrlEmpty(value)
        },
        {
          name: 'opcoes',
          title: 'Opções',
          sortField: 'opcoes',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ]
    },
    getActiveFilterList () {
      return this.activeFilterList
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      this.getReportProposalData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>
  <!-- Adjust padding and margin on mobile views -->
  .card-body {
    padding: 0.5rem 1.8rem;
  }

  @media (max-width: 576px) {

    .card-body {
      padding: 0.8rem 0.2rem;
    }

    .gray-box {
      padding: 0.8rem 0.2rem;
    }
  }

  .dark-gray-box {
    background-color: #e3e2e1;
    border-radius: 0 0 38px 38px;
    margin-bottom: 10px;
    padding: 25px;
    padding-left: 75px;
    padding-right: 75px;
    padding-bottom: 1px;
  }

  .dark-gray-box .line {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 27px;
  }

  .label {
    padding-left: 42px;
    padding-top: 7px;
    padding-bottom: 7px;
    background-position: center left;
    background-repeat: no-repeat;
    margin-left: 10px;
    font-size: 11px;
    font-weight: bold;

  }

  .label.air {
    background-image: url("/static/images/report/air_icon.png");
  }

  .label.ship {
    background-image: url("/static/images/report/ship_icon.png");
  }

  .label.lcl {
    background-image: url("/static/images/report/lcl_icon.png");
  }

  .label.fcl {
    background-image: url("/static/images/report/fcl_icon.png");
  }

  .label.insurance {
    background-image: url("/static/images/report/insurance_icon.png");
  }

  .label.green {
    background-image: url("/static/images/report/green_icon.png");
    padding-left: 20px;
  }

  .label.yellow {
    background-image: url("/static/images/report/yellow_icon.png");
    padding-left: 20px;
  }

  .label.red {
    background-image: url("/static/images/report/red_icon.png");
    padding-left: 20px;
  }

  .card-legend, .card-title {
    color: #84754E;
    text-align: center;
    font-size: 18px;
  }

  .card-title, .card-title b {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 19px;

  }

  .summary-box .message{
    font-weight: 200;
    font-size: 14px;
    font-family: pluto_sanscond_light;
  }

  .gray-box {
    background-color: #F3F1ED;
    border-radius: 38px 38px 0 0;
    margin-top: 82px;
  }

  /* Sellect Section */
  .row.seller-box {
    margin-bottom: -100px;
  }

  .seller {
    text-align: center;
    top: -100px;

  }

  .seller img {
    border: 10px solid #F3F1ED;
    border-radius: 148px;
    width: 148px;
    position: relative;
    overflow: hidden;
  }

  .seller h2 {
    margin: 12px 0px;
    font-size: 25px;
  }

  .summary-box {
    margin-top: 39px;
  }

  /* Message box */
  .message-box {
    margin: 0 2.8rem;
    color: #84734C;
    font-size: 14px;
  }

  @media (max-width: 576px) {
    .message-box {
      margin: 0 0.8rem;
    }
  }

  /* Details */
  .detail-box .indicator-box {
    background-color: #fefefe;
    border-radius: 0 0 40px 40px;
    padding: 50px 60px;
  }

  .detail-box.first .indicator-box {

    border-radius: 40px 40px 0 0;
    padding: 0 60px;
    padding-top: 50px;
  }

  .detail-box.middle .indicator-box {

    border-radius: 0px 0px 0px 0px;
    padding: 0 60px;
    padding-bottom: 65px;
  }

  .detail-box.first {
    margin-top: 70px;

  }
  .detail-box.last .indicator-box, .detail-box.simple .indicator-box {
    padding-bottom: 45px;

  }

  .detail-box.simple {
    margin-top: 70px;
  }

  .detail-box.simple .indicator-box {
    border-radius: 40px 40px 40px 40px;
  }

  /* Activities */

  .activities h3 {
    margin-top: 0;
    color: #84754E;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
  }

  .activities h3.message {
    margin-top: 45px;
    font-size: 19px;
    font-weight: bold;
  }

  .activities .card-title {
    text-align: center;
    font-size: 13px !important;
    font-weight: bold;
    color: #84754E;
  }

  .activities .icon {

    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 55px;
  }

  .activities .icon.price {
    background-image: url("/static/images/report/price_icon.png");
  }

  .activities .icon.visits {

    background-image: url("/static/images/report/visits_icon.png");
  }

  .activities .icon.presentation {
    background-image: url("/static/images/report/presentations_icon.png");
  }

  .activities .icon.callPhones {
    background-image: url("/static/images/report/cellPhone_icon.png");
  }

  /* right operation / modal label */
  .detail-box .col-md-2 {
    padding: 0;
  }

  .detail-box .col-md-2 p {
    top: 113px;
    font-size: 26px;
    font-weight: 200;
    color: #84754E;
  }

  .section-title, .section-title b {
    font-size: 26px;
    font-weight: 200;
    color: #84754E;
    font-family: 'Pluto Sans';
  }

  .section-title b {
    font-family: 'Pluto Sans Medium';
  }

  .detail-box .col-md-2 p {
    top: 197px;
    left: 0;
    padding-left: 58px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 23px;
    font-weight: 200;
    position: relative;
    background-image: url("/static/images/report/carret.png");
    background-position: -9px center;
    background-repeat: no-repeat;

  }

  .chart-label .medium {
    font-size: 18px
  }

  .card-legend .small {
    font-size: 11px !important;
  }

  .card-title.info {
    margin-top: 59px;
  }

  .card-title.info, .card-legend.info {
    font-size: 15px;
  }

  .full-heigth-scroll {
    max-height: 75vh;
    overflow: auto;
  }

  .full-heigth-scroll .table thead th {
    background-color: #fff;
    position: sticky;
    top: 0;
  }

  .disableLabel span{
    background-color: rgba(132,117,78,0.6) !important;
    border-color: rgba(132,117,78,0.1) !important;
  }
</style>
