<template>
  <div :class="classOut">
    <div class="card-body text-center py-3">
      <div class="row col-12 cold-sm-12 col-md-12 p-0">
        <div class="col-6 col-sm-6 col-md-6 p-0 br-1">
          <div class="d-flex mb-5">
            <div class="col-12 cold-sm-12 col-md-12 p-0">
              <h5 class="box-title mb-0 text-center">{{ comparLeft }}</h5>
            </div>
          </div>
          <div class="flexbox mb-2">
            <div class="h1 mb-0 text-center col-5 cold-sm-5 col-md-5 p-0">
              {{ prefixLeft }} {{ showNumber(valueLeft) }}{{ sufixLeft }}
            </div>
            <span class="text-success font-18 text-center col-7 cold-sm-7 col-md-7 p-2" :class="{ 'text-success': percentLeft >= 0, 'text-danger': percentLeft < 0 }">
              <i class="mr-2" :class="{ 'ft-arrow-up-right': percentLeft >= 0, 'ft-arrow-down-left': percentLeft < 0 }"></i>
              {{ numberFormat(getPercent(percentLeft), 2,',','.') }}%
            </span>
          </div>
          <div class="text-muted px-5 py-3 text-center">{{ descriptionLeft }}</div>
        </div>

        <div class="col-6 col-sm-6 col-md-6 p-0">
          <div class="d-flex mb-5">
            <div class="col-12 cold-sm-12 col-md-12 p-0">
              <h5 class="box-title mb-0 text-center">{{ comparRight }}</h5>
            </div>
          </div>
          <div class="flexbox mb-2">
            <div class="h1 mb-0 text-center col-5 cold-sm-5 col-md-5">
              {{ prefixRight }} {{ showNumber(valueRight) }}{{ sufixRight }}
            </div>
            <span class="text-success font-18 text-center col-7 cold-sm-7 col-md-7" :class="{ 'text-success': percentRight >= 0, 'text-danger': percentRight < 0 }">
              <i class="mr-2" :class="{ 'ft-arrow-up-right': percentRight >= 0, 'ft-arrow-down-left': percentRight < 0 }"></i>
              {{ numberFormat(getPercent(percentRight), 2,',','.') }}%
            </span>
          </div>
          <div class="text-muted px-5 py-3 text-center">{{ descriptionRight }}</div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 p-0 bt-1">
        <div class="text-muted px-5 py-3 text-center">{{ getComparison(valueLeft, valueRight, comparisonText) }}</div>
      </div>

      <!-- Cards Operação e Modal -->
      <div class="col-12 col-sm-12 col-md-12 p-0 bt-1">
        <div class="row">
          <!-- Card box1 -->
          <div class="col-4 col-sm-4 col-md-4 br-1">
            <h5 class="box-title mb-0 text-center col-12 col-sm-12 col-md-12 mb-3">{{ titleBox1 }}</h5>
            <div class="row">
              <div class="h4 mb-0 text-center col-4 cold-sm-4 col-md-4 p-0">
                {{ showNumber(valueBox1) }}
              </div>
              <div class="text-center col-8 cold-sm-8 col-md-8 p-0">
                <span class="text-success font-12" :class="{ 'text-success': percentBox1 >= 0, 'text-danger': percentBox1 < 0 }">
                  <i class="mr-2" :class="{ 'ft-arrow-up-right': percentBox1 >= 0, 'ft-arrow-down-left': percentBox1 < 0 }"></i>
                  {{ numberFormat(getPercent(percentBox1), 2,',','.') }}%
                </span>
              </div>
            </div>
            <div class="text-muted py-2 desc-txt text-center">{{ descriptionBox1 }}</div>
          </div>
          <!-- Card box2 -->
          <div class="col-4 col-sm-4 col-md-4 br-1">
            <h5 class="box-title mb-0 text-center col-12 col-sm-12 col-md-12 mb-3">{{ titleBox2 }}</h5>
            <div class="row">
              <div class="h4 mb-0 text-center col-4 cold-sm-4 col-md-4 p-0">
                {{ showNumber(valueBox2) }}
              </div>
              <div class="text-center col-8 cold-sm-8 col-md-8 p-0">
                <span class="text-success font-12" :class="{ 'text-success': percentBox2 >= 0, 'text-danger': percentBox2 < 0 }">
                  <i class="mr-2" :class="{ 'ft-arrow-up-right': percentBox2 >= 0, 'ft-arrow-down-left': percentBox2 < 0 }"></i>
                  {{ numberFormat(getPercent(percentBox2), 2,',','.') }}%
                </span>
              </div>
            </div>
            <div class="text-muted desc-txt py-2 text-center">{{ descriptionBox2 }}</div>
          </div>
          <!-- Card box3 -->
          <div class="col-4 col-sm-4 col-md-4">
            <h5 class="box-title mb-0 text-center col-12 col-sm-12 col-md-12 mb-3">{{ titleBox3 }}</h5>
            <div class="row">
              <div class="h4 mb-0 text-center col-4 cold-sm-4 col-md-4 p-0">
                {{ showNumber(valueBox3) }}
              </div>
              <div class="text-center col-8 cold-sm-8 col-md-8 p-0">
                <span class="text-success font-12" :class="{ 'text-success': percentBox3 >= 0, 'text-danger': percentBox3 < 0 }">
                  <i class="mr-2" :class="{ 'ft-arrow-up-right': percentBox3 >= 0, 'ft-arrow-down-left': percentBox3 < 0 }"></i>
                  {{ numberFormat(getPercent(percentBox3), 2,',','.') }}%
                </span>
              </div>
            </div>
            <div class="text-muted desc-txt py-2 text-center">{{ descriptionBox3 }}</div>
          </div>
          <!-- Card box4 -->
          <div class="col-4 col-sm-4 col-md-4 br-1 bt-1">
            <h5 class="box-title mb-0 text-center col-12 col-sm-12 col-md-12 mb-3">{{ titleBox4 }}</h5>
            <div class="row">
              <div class="h4 mb-0 text-center col-4 cold-sm-4 col-md-4 p-0">
                {{ showNumber(valueBox4) }}
              </div>
              <div class="text-center col-8 cold-sm-8 col-md-8 p-0">
                <span class="text-success font-12" :class="{ 'text-success': percentBox4 >= 0, 'text-danger': percentBox4 < 0 }">
                  <i class="mr-2" :class="{ 'ft-arrow-up-right': percentBox4 >= 0, 'ft-arrow-down-left': percentBox4 < 0 }"></i>
                  {{ numberFormat(getPercent(percentBox4), 2,',','.') }}%
                </span>
              </div>
            </div>
            <div class="text-muted desc-txt py-2 text-center">{{ descriptionBox4 }}</div>
          </div>
          <!-- Card box5 -->
          <div class="col-4 col-sm-4 col-md-4 br-1 bt-1">
            <h5 class="box-title mb-0 text-center col-12 col-sm-12 col-md-12 mb-3">{{ titleBox5 }}</h5>
            <div class="row">
              <div class="h4 mb-0 text-center col-4 cold-sm-4 col-md-4 p-0">
                {{ showNumber(valueBox5) }}
              </div>
              <div class="text-center col-8 cold-sm-8 col-md-8 p-0">
                <span class="text-success font-12" :class="{ 'text-success': percentBox5 >= 0, 'text-danger': percentBox5 < 0 }">
                  <i class="mr-2" :class="{ 'ft-arrow-up-right': percentBox5 >= 0, 'ft-arrow-down-left': percentBox5 < 0 }"></i>
                  {{ numberFormat(getPercent(percentBox5), 2,',','.') }}%
                </span>
              </div>
            </div>
            <div class="text-muted desc-txt py-2 text-center">{{ descriptionBox5 }}</div>
          </div>
          <!-- Card box6 -->
          <div class="col-4 col-sm-4 col-md-4 bt-1">
            <h5 class="box-title mb-0 text-center col-12 col-sm-12 col-md-12 mb-3">{{ titleBox6 }}</h5>
            <div class="row">
              <div class="h4 mb-0 text-center col-4 cold-sm-4 col-md-4 p-0">
                {{ showNumber(valueBox6) }}
              </div>
              <div class="text-center col-8 cold-sm-8 col-md-8 p-0">
                <span class="text-success font-12" :class="{ 'text-success': percentBox6 >= 0, 'text-danger': percentBox6 < 0 }">
                  <i class="mr-2" :class="{ 'ft-arrow-up-right': percentBox6 >= 0, 'ft-arrow-down-left': percentBox6 < 0 }"></i>
                  {{ numberFormat(getPercent(percentBox6), 2,',','.') }}%
                </span>
              </div>
            </div>
            <div class="text-muted desc-txt py-2 text-center">{{ descriptionBox6 }}</div>
          </div>
        </div>
        </div>
    </div>
  </div>

</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

export default {
  name: 'DetailedSearchesBox',
  props: {
    classOut: {
      type: String,
      required: false,
      default: 'card card-fullheight'
    },
    valueFormatter: {
      type: Function,
      required: false
    },
    comparLeft: {
      type: String,
      required: true
    },
    comparRight: {
      type: String,
      required: true
    },
    prefixLeft: {
      type: String,
      required: false,
      default: ''
    },
    prefixRight: {
      type: String,
      required: false,
      default: ''
    },
    sufixLeft: {
      type: String,
      required: false,
      default: ''
    },
    sufixRight: {
      type: String,
      required: false,
      default: ''
    },
    descriptionLeft: {
      type: String,
      required: false,
      default: ''
    },
    descriptionRight: {
      type: String,
      required: false,
      default: ''
    },
    comparisonText: {
      type: String,
      required: false,
      default: ''
    },
    valueLeft: {
      type: Number,
      required: true,
      default: 0
    },
    valueRight: {
      type: Number,
      required: true,
      default: 0
    },
    percentLeft: {
      type: Number,
      required: true,
      default: 0
    },
    percentRight: {
      type: Number,
      required: true,
      default: 0
    },
    titleBox1: {
      type: String,
      required: false
    },
    valueBox1: {
      type: Number,
      required: false
    },
    percentBox1: {
      type: Number,
      required: false
    },
    descriptionBox1: {
      type: String,
      required: false
    },
    titleBox2: {
      type: String,
      required: false
    },
    valueBox2: {
      type: Number,
      required: false,
      default: 0
    },
    percentBox2: {
      type: Number,
      required: false,
      default: 0
    },
    descriptionBox2: {
      type: String,
      required: false,
      default: ''
    },
    titleBox3: {
      type: String,
      required: false
    },
    valueBox3: {
      type: Number,
      required: false,
      default: 0
    },
    percentBox3: {
      type: Number,
      required: false,
      default: 0
    },
    descriptionBox3: {
      type: String,
      required: false,
      default: ''
    },
    titleBox4: {
      type: String,
      required: false
    },
    valueBox4: {
      type: Number,
      required: false,
      default: 0
    },
    percentBox4: {
      type: Number,
      required: false,
      default: 0
    },
    descriptionBox4: {
      type: String,
      required: false,
      default: ''
    },
    titleBox5: {
      type: String,
      required: false
    },
    valueBox5: {
      type: Number,
      required: false,
      default: 0
    },
    percentBox5: {
      type: Number,
      required: false,
      default: 0
    },
    descriptionBox5: {
      type: String,
      required: false,
      default: ''
    },
    titleBox6: {
      type: String,
      required: false
    },
    valueBox6: {
      type: Number,
      required: false,
      default: 0
    },
    percentBox6: {
      type: Number,
      required: false,
      default: 0
    },
    descriptionBox6: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    getPercent (percentValue) {
      if (percentValue < 0) {
        return percentValue * -1.0
      }
      return percentValue
    },
    showNumber (number, thousandsSep, defaultValue) {
      if (this.valueFormatter) {
        return this.valueFormatter(number)
      }

      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'
      if (number !== parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },

    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    getComparison (valueLeft, valueRight, comparisonText) {
      return ((100 * valueRight) / valueLeft).toFixed(1) + '% ' + comparisonText
    }
  },
  computed: {
  }
}

</script>

<style scoped>
  .text-success {
    color: #3c763d;
  }
  .h1 {
    font-size: 2.2rem;
  }
  .bt-1 {
    border-top: 1px solid #cecece;
  }
  .br-1 {
    border-right: 1px solid #cecece;
  }
  .desc-txt {
    font-size: 12px; line-height: 14px; margin-bottom: 6px;
  }
</style>
