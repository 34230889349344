<template>
  <div class="row">
    <div class="col-12 modal-box">
      <div class="close-button mb-4">
        <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
          <span><i class="text-primary rc-Ativo-36"> </i></span>
        </div>
      </div>
      <div class="card-header border-0">
        <h5 class="box-title">Clientes</h5>
      </div>
      <div class="content">
      <div class="row">
        <div class="col-12 col-md-12">
            <div>
              <custom-vue-table
                ref="CustomerTable"
                http-method="get"
                :api-url="loadCustomerEndpoint"
                :fields="fieldsTable"
                :sort-order="sortOrder"
                :append-params="CustomerActiveFilters"
                track-by="id"
              >
              </custom-vue-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomVueTable from '@/components/Table/CustomVueTable'

export default {
  name: 'CustomerModal',
  components: {
    CustomVueTable
  },
  data () {
    return {
      loadCustomerEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/indicator/proposal/customer',
      sortOrder: [
        { field: 'buscas', direction: 'desc' }
      ]
    }
  },
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    }
  },
  computed: {
    fieldsTable () {
      return [
        {
          name: 'cliente',
          title: 'Cliente',
          sortField: 'cliente',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'opcoesdisponiveis',
          title: 'Com opções',
          sortField: 'opcoesdisponiveis',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'buscassemopcoes',
          title: 'Sem opções',
          sortField: 'buscassemopcoes',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'buscas',
          title: 'Total',
          sortField: 'buscas',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ]
    },
    CustomerActiveFilters () {
      return this.filters
    }
  }
}
</script>

<style scoped>

  .card-header {
    margin-top: -30px;
  }

  .modal-box{
    padding: 50px;
    padding-bottom: 30px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  p {
    text-align: justify;
  }

  .content p {
    font-size: 14px;
    color: #4D4F5C;
    font-family: 'pluto_sanslight';
    /*padding-bottom: 20px;*/
  }
</style>

<style>
  .modal-box .md-checkbox .md-checkbox-label {
    font-size: 85%;
    text-align: left;
    font-family: 'pluto_sanslight';
  }
</style>
