<template>
  <div class="chart-container" style="width:100%"
    v-bind:style="{ width: width}"
  >
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <canvas :id="id"></canvas>
  </div>
</template>
<script>
// Loading
import Chart from 'chart.js'
import 'chart.js/dist/Chart.min.css'
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Loading
  },
  props: {
    yGridLines: {
      type: Number,
      required: false,
      default: 4
    },
    gradient: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: String,
      required: false,
      default: null
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    data: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: true
    },
    showLegend: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      chart: null,
      id: null,
      fullPage: false,
      chartData: {},
      options: { responsive: true, maintainAspectRatio: true, spanGaps: true }
    }
  },
  created () {
    this.id = 'area_chart_' + this._uid
  },
  mounted () {
    const ctx = global.document.getElementById(this.id)
    let _this = this
    this.chartData = {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: [{
          label: 'Quantidade Cotações',
          borderColor: '#84754e',
          pointBackgroundColor: '#84754e',
          borderWidth: 1.3,
          fill: this.gradient,
          pointBorderColor: '#84754e',
          pointRadius: 2,
          backgroundColor: (ctx) => {
            if (this.gradient) {
              const canvas = ctx.chart.ctx

              const gradient = canvas.createLinearGradient(0, 0, 0, 450)
              gradient.addColorStop(0, 'rgba(132, 117, 78, 0.7)')
              gradient.addColorStop(0.5, 'rgba(132, 117, 78, 0.25)')
              // gradient.addColorStop(1, 'rgba(132, 117, 78, 0)')

              return gradient
            } else {
              return null
            }
          },
          data: this.data

        }]
      },
      options: {
        legend: {
          display: this.showLegend
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              var label = data.labels[tooltipItem[0].index]

              if (!label) {
                return null
              }

              return data['labels'][tooltipItem[0]['index']]
            },
            label: function (tooltipItem, data) {
              var valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              if (valor === null) {
                return null
              }

              if (valor || valor === 0) {
                return _this.$util.formatNumber(valor, 0, ',', '.')
              }

              return false
            }
          },
          backgroundColor: '#666666',
          titleFontSize: 10,
          titleSpacing: 10,
          titleMarginBottom: 10,
          bodySpacing: 4,
          titleFontColor: '#ffffff',
          bodyFontColor: '#ffffff',
          bodyFontSize: 14,
          cornerRadius: 10,
          yPadding: 10,
          xPadding: 10,
          displayColors: false
        },
        responsive: true,
        spanGaps: true,
        maintainAspectRatio: true,
        aspectRatio: 2,
        scales: {
          yAxes: [{
            gridLines: {
              color: '#e9e9e9'
            },
            ticks: {
              beginAtZero: true,
              stepSize: this.stepSize()
            }
          }],
          xAxes: [{
            gridLines: {
              color: 'rgba(0, 0, 0, 0)'
            }
          }],
          x: {
            border: {
              display: true
            },
            grid: {
              display: true,
              drawOnChartArea: true,
              drawTicks: true
            }
          },
          y: {
            border: {
              display: false
            },
            grid: {
              color: function (context) {
                if (context.tick.value > 0) {
                  return 'green'
                } else if (context.tick.value < 0) {
                  return 'red'
                }

                return '#000000'
              }
            }
          }
        }
      }
    }

    this.chart = new Chart(ctx, this.chartData)
  },
  methods: {
    stepSize () {
      let granuralidadeMinima = 100
      let maxValue = Math.max(...this.data)

      let resto = maxValue % granuralidadeMinima

      if (resto !== 0) {
        maxValue = maxValue + (granuralidadeMinima - resto)
      }

      return (maxValue) / this.yGridLines
    }
  },
  watch: {
    data (newVal) {
      let data = Object.assign({}, this.chartData)
      Vue.set(data.data.datasets[0], 'data', newVal)
      Vue.set(data.options.scales.yAxes[0].ticks, 'stepSize', this.stepSize())

      this.chartData = null
      this.chartData = data

      this.chart.update()
    },
    showLegend (newValue) {
      let data = Object.assign({}, this.chartData)
      this.chartData.options.legend.display = newValue

      this.chartData = null
      this.chartData = data
      this.chart.update()
    },
    labels (newValue) {
      Vue.set(this.chartData.data, 'labels', newValue)
      this.chart.update()
    },
    height (newValue) {
      let data = Object.assign({}, this.chartData)
      Vue.set(data, 'height', newValue)
      this.chartData = null
      this.chartData = data
    }
  }
}
</script>
